<template>
  <section class="bonds">
    <div class="bonds__wrapper" v-if="bonds && bonds.length" v-bind="initSocketEvents">
      <h2 class="bonds__title">Bonos</h2>
      <div class="bonds__card" v-for="(bond, idx) in bonds" :key="idx">
        <h4 class="bonds__subtitle">{{ bond.title }}</h4>
        <div class="bonds__imageContainer" v-for="(img, key) in bond.images" :key="`${img.alt}-${key}`">
          <div class="bonds__container" v-if="img.description">
            <p class="bonds__description" v-html="img.description"></p>
          </div>
          <img :src="img.src" :alt="img.alt" loading="lazy" />
        </div>
        <div class="bonds__realTime bonds__contentRow">
          <div class="bonds__comments">
            <likes :likes="bond.likes" :postId="bond._id" nameEvent="likeBonds" endpoint="/bonds/likes" />
            <div class="bonds__commentsBtn">
              <div class="bonds__comments" @click="handleComments({ id: bond._id, open: true })">
                <iconic :class="['bonds__commentIco']" :name="bond.comments ? 'comments_solid' : 'comments'" />
                <p v-if="bond.comments">{{ bond.comments }}</p>
              </div>
            </div>
            <Comments v-if="showComments[bond._id]" :newId="bond._id" :isBond="true" :closeModal="() => handleComments({ id: bond._id, open: false })" />
          </div>
        </div>
        <div class="bonds__likesUsers">
          <likesInfo :likes="bond.likes" :postId="bond._id" endpoint="/bonds/likes" />
        </div>
      </div>
    </div>
    <div class="bonds__wrapper" v-else>
      <p v-if="!isLoading" class="bonds__subtitle bonds__subtitle--dark">No existen bonos vigentes</p>
    </div>
  </section>
</template>

<script>
  import { socket } from "@/socket";
  import likes from "@/components/likes/Likes.vue";
  import Comments from "@/components/news/comments/Comments.vue";
  import likesInfo from "@/components/likes/LikeInfo.vue";

  export default {
    components: {
      likes,
      Comments,
      likesInfo,
    },
    data() {
      return {
        bonds: [],
        showComments: {},
        isLoading: false,
      };
    },
    computed: {
      initSocketEvents() {
        if (this.$socketState) {
          socket.on("likeBonds", this.updateLikes);
        }
      },
    },
    methods: {
      handleComments({ id, open }) {
        open ? document.body.classList.add("onmodal") : document.body.classList.remove("onmodal");
        this.showComments = { ...this.showComments, [id]: open };
        if (!open) this.$store.commit("news/closeComments", { id });
      },
      updateLikes(like) {
        const userId = this.$userToken._id;
        this.bonds = this.bonds.map((bond) => {
          if (bond._id === like.like.bondId) {
            if (like.status === "like") {
              bond.likes.total++;
              bond.likes.likesUser.unshift(like.like);
              if (like.like.userId._id === userId) bond.likes.isMyLike = true;
            }
            if (like.status === "unlike") {
              const i = bond.likes.likesUser.findIndex((_like) => _like.userId._id === like.like.userId);
              bond.likes.total--;

              if (like.like.userId === userId) bond.likes.isMyLike = false;
              if (i !== -1) {
                bond.likes.likesUser.splice(i, 1);
              }
            }
          }
          return bond;
        });
      },
    },
    async beforeMount() {
      this.isLoading = true;
      const { data } = await this.$axios.get("/bonds");
      if (data) {
        this.bonds = data.data;
      }
      this.isLoading = false;
    },
    beforeDestroy() {
      socket.off("likeBonds", this.updateLikes);
    },
  };
</script>

<style lang="scss">
  .bonds {
    max-width: 820px;
    padding-top: 20px;
    &__wrapper {
      width: 100%;
    }
    &__title {
      text-align: left;
      font-size: 32px;
    }
    &__card {
      padding: $mpadding;
      border-radius: $mradius/1.7;
      background-color: $white;
      box-shadow: 0 2px 5px $alto;
    }
    &__subtitle {
      font-size: 2em;
      &--dark {
        color: $chicago;
        text-align: center;
      }
    }
    &__likesUsers {
      margin-top: 10px;
    }
    &__description,
    &__subtitle {
      padding: $mpadding/2 0;
      white-space: pre-line;
    }
    &__imageContainer {
      width: 100%;
      img {
        width: 100%;
      }
    }
    &__container,
    &__imageContainer,
    &__card {
      margin: $mpadding auto;
    }
    &__comments {
      display: flex;
    }
    &__commentsBtn {
      user-select: none;
      cursor: pointer;
      p {
        font-size: 14px;
        padding: 0 $mpadding / 2;
      }
    }
    &__contentRow {
      @include Row(flex-start);
    }
  }
</style>
