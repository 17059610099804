<script>
  import modalLikes from "@/components/modals/ModalLikes";

  export default {
    name: "LikeInfo",
    components: { modalLikes },
    props: ["likes", "postId", "endpoint", "news"],
    data() {
      return {
        isShowLikes: false,
      };
    },
    methods: {
      showNewsLikes: function() {
        if (!this.news || !this.news._id) {
          this.isShowLikes = true;
        } else {
          this.$store.state.news.NewsLikesVisor = this.news;
        }
      },
    },
    computed: {
      getUsers() {
        return this.likes.likesUser?.slice(0, 3);
      },
      getText() {
        try {
          const [{ userId }] = this.likes.likesUser || [];
          const _user = userId?.role === "model" ? userId?.artisticName : userId?.user;
          return `${_user}`;
        } catch (e) {
          return "";
        }
      },
      getTextMore() {
        if (this.likes.likesUser.length > 1) {
          return ` ${this.likes.total - 1} más`;
        }
        return "";
      },
    },
  };
</script>
<template>
  <div class="likesInfo" v-if="this.likes.likesUser.length">
    <div class="likesInfo__container">
      <div class="likesInfo__avatars">
        <Avatar class="likesInfo__avatar" v-for="(user, idx) in getUsers" :key="idx" :user="user.userId" />
      </div>
      <div class="likesInfo__txt">
        <span v-if="getText">
          Me gusta por
        </span>
        <span bold>
          {{ getText }}
        </span>
        <span v-if="getTextMore">y</span>
        <span class="likesInfo__more" @click="showNewsLikes" bold>{{ getTextMore }}</span>
      </div>
    </div>
    <modalLikes :idPost="postId" :endpoint="endpoint" v-if="isShowLikes" @close="isShowLikes = $event" />
  </div>
  <div v-else class="likesInfo NoLikes">
    <span class="likesInfo-copyr" v-html="`&copy;`"></span>
    <span class="likesInfo-credits" v-html="`My Space 1A`"></span>
  </div>
</template>
<style lang="scss">
  .likesInfo {
    &__container,
    &__avatars {
      @include Row(flex-start, center);
    }
    &__avatar {
      width: 22px;
      height: 22px;
      flex-shrink: 0;
      &:nth-child(2) {
        margin-left: -9px;
        z-index: 1;
      }
      &:last-child {
        margin-left: -9px;
        z-index: 0;
      }
      &:first-child {
        margin-left: 0;
        z-index: 1;
      }
    }
    &__txt {
      margin-left: 5px;
      font-size: 0.9em;
    }
    &__more {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    &.NoLikes {
      display: flex;
      align-items: center;
      gap: $mpadding/2;
      opacity: 0.7;
      margin-top: $space-0;
    }
    &-copyr {
      font-weight: bold;
      font-size: 120%;
    }
    &-credits {
      font-size: 80%;
    }
  }
</style>
